import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { BaseService } from '@aifs-shared/common/base-service';
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse } from '@aifs-shared/common/base-response';

import { Observable, Subject, throwError } from 'rxjs';

// Classes
import { ApplicationInterviewDetails } from './application-interview-details';
import { InterviewLocation } from '@aifs-shared/interview/interview-location';
import { Interview } from './interview';
import { Interviewer } from '../interviewer/interviewer';
import { catchError } from 'rxjs/operators';
import { InterviewerWithLocation } from '../interviewer/interviewer-with-location';

@Injectable()
export class InterviewService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    /**
     * @description Get count of active interviewwers in the desired country
     * @param countryCode (string) - ISO country code
     */
    public getActiveInterviewersInCountry(countryCode: string): Observable<ActiveInterviewersResponse> {
        return this.http.get<ActiveInterviewersResponse>(`${environment.ServiceUrl_InterviewersAvailableInCountry(countryCode)}`)
    }

    /**
     * @description Get active online interviewers for the desired country
     * @param countryCode (string) - ISO country code
     * @returns OnlineInterviewers
     */
    public getOnlineInterviewers(countryCode: string): Observable<OnlineInterviewers> {
        let subject = new Subject<OnlineInterviewers>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_InterviewersAvailableInCountryOnline(countryCode)}`)
            .subscribe(
                (data: BaseResponse) => {
                    let response = this.getResultData<OnlineInterviewers>(data);
                    subject.next(response);
                },
                error => {
                    this.handleError(error);
                }
            )

        return subject;
    }

    /**
     * @description Get active online interviewers for the desired country
     * @param countryCode (string) - ISO country code
     * @returns OnlineInterviewers
     */
     public getOnlineInterviewersWithLocations(countryCode: string): Observable<InterviewerWithLocation[]> {
        let subject = new Subject<InterviewerWithLocation[]>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_InterviewersAvailableInCountryOnlineWithLocations(countryCode)}`)
            .subscribe(
                (data: BaseResponse) => {
                    let response = this.getResultData<InterviewerWithLocationResponse>(data);
                    subject.next(response.interviewersWithLocations);
                },
                error => {
                    this.handleError(error);
                }
            )

        return subject;
    }

    /**
     * Return interviewers near the passed geo point
     * @param countryCode (string) - ISO country code
     * @param lat (number) - latitude
     * @param long (number) - logitude
     */
    public getInterviewLocationsNearest(countryCode: string, lat: number, long: number): Observable<InterviewLocation[]> {
        let subject = new Subject<InterviewLocation[]>();
        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_InterviewLocationsNearest}/${countryCode}/${lat}/${long}`)
            .subscribe({
                next: (data: any) => {
                    let response = data.result["locations"];
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getInterviewLocation(id: number): Observable<InterviewLocation> {
        let subject = new Subject<InterviewLocation>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_InterviewLocationById}/${id}`)
            .subscribe({
                next: (data: any) => {
                    let response = data.result["location"];
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public requestInterview(applicationId: number, interviewerId: number, locationId: number, interviewReady: boolean, changeResponseId?: number, oldInterviewerId?: number, comments?: string): Observable<InterviewLocation> {
        let subject = new Subject<InterviewLocation>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_RequestInterview}`,
                { applicationId, interviewerId, locationId, changeResponseId, oldInterviewerId, comments, interviewReady })
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<InterviewLocation>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                    subject.error(error);
                }
            });

        return subject;
    }

    public requestOnlineInterview(applicationId: number, interviewerId: number, interviewDate: string, interviewStartTime: string, applicantTzTimezoneCode: string): Observable<number> {
        const s = new Subject<number>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_RequestOnlineInterview}`,
            { applicationId, interviewerId, interviewDate, interviewStartTime, applicantTzTimezoneCode})
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )            
            .subscribe({
                next: (data: any) => {
                    // console.log(`Got data: ${JSON.stringify(data)}`);
                    const response = this.getResultData<OnlineInterviewRequest>(data);
                    // console.log(`Got Response: ${JSON.stringify(response)}`);
                    if(response.requestId) s.next(response.requestId); else s.next(0);
                },
                error: (error) => {
                    this.handleError(error);
                    s.error(error);
                }
            });

        return s;
    }

    public completeOnlineInterview(applicationId: number): Observable<CompleteOnlineInterview> {
        const s = new Subject<CompleteOnlineInterview>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_CompleteOnlineInterview}`,
                { applicationId })
            .pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
            .subscribe({
                next: (data: any) => {
                    // console.log(`Got data: ${JSON.stringify(data)}`);
                    const response = this.getResultData<CompleteInterviewRequest>(data);
                    // console.log(`Got Response: ${JSON.stringify(response)}`);
                    s.next(response.result);
                },
                error: (error) => {
                    this.handleError(error);
                    s.error(error);
                }
            });

        return s;
    }

    public getInterviewDetails(interviewId: number): Observable<Interview> {
        let subject = new Subject<Interview>();

        // console.debug("getInterviewDetails" + interviewId);
        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_Interview}/${interviewId}`)
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<Interview>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public getApplicationInterviewDetails(applicationId: number): Observable<ApplicationInterviewDetails> {
        let subject = new Subject<ApplicationInterviewDetails>();

        this.http
            .get<BaseResponse>(`${environment.ServiceUrl_ApplicationInterview(applicationId)}`)
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<ApplicationInterviewDetails>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public confirmInterview(applicationId: number, interviewId: number): Observable<boolean> {
        let subject = new Subject<boolean>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_InterviewRejectOrApproveBase}/${interviewId}/confirm`,
                { applicationId, interviewId })
            .subscribe({
                next: (data: any) => {
                    let response = data.resultCode == 1;
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public rejectInterview(applicationId: number, interviewId: number, rejectReason: string): Observable<InterviewLocation> {
        let subject = new Subject<InterviewLocation>();

        this.http
            .post<BaseResponse>(`${environment.ServiceUrl_InterviewRejectOrApproveBase}/${interviewId}/reject`,
                { applicationId, interviewId, rejectReason })
            .subscribe({
                next: (data: any) => {
                    let response = this.getResultData<InterviewLocation>(data);
                    subject.next(response);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return subject;
    }

    public onlineInterviewAvailability(applicationId: number, timezoneCode: string): Observable<OnlineInterviewAvailability> {
        const s = new Subject<OnlineInterviewAvailability>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_OnlineInterviewAvailability(applicationId), 
            { applicationId: applicationId, applicantTimezoneCode: timezoneCode })
            .subscribe({
                next: (data: any) => {
                        let response = this.getResultData<OnlineInterviewAvailability>(data);
                        s.next(response);
                    },
                    error: (error: any) => {
                        this.handleError(error);
                    }
                });

        return s;
    }

    public onlineInterviewRemoveReservation(applicationId: number): Observable<boolean> {
        const s = new Subject<boolean>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_OnlineInterviewRemoveReservation(applicationId),
            { applicationId: applicationId })
            .subscribe({
               next: (data) => {
                    s.next(true);
                },
                error: (error: any) => {
                    this.handleError(error);
                }
            });

        return s;
    }

    // 
    // Store the user's desired interview location
    // 
    public setSelectedInterviewLocation(selected: InterviewLocation | undefined): void {
        if (!selected) {
            this.userSelectedInterviewLocation = undefined;
            return;
        }

        if (!this.userSelectedInterviewLocation || this.userSelectedInterviewLocation.id != selected.id) {
            this.userSelectedInterviewLocation = selected;
        }
    }

    //
    // Return the user's desired interview location
    //
    public getSelectedInterviewLocation(): InterviewLocation | undefined {
        return this.userSelectedInterviewLocation;
    }

    public setInterviewDetails(detailsInterview: Interview): void {
        this.interview = detailsInterview;
    }

    public getSelectedInterviewDetails(): Interview {
        return this.interview;
    }

    public checkSlotReservations(interviewDate: string, interviewTime: string): Observable<ReservationResponse> {
        const s = new Subject<ReservationResponse>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_OnlineInterviewCheckReservation,
                {date: interviewDate, time: interviewTime})
            .subscribe({
                next: (data) => {
                    let response = this.getResultData<ReservationResponse>(data);
                    s.next(response);
                },
                error: (error) => this.handleError(error)             
            });

        return s;
    }

    // Data
    userSelectedInterviewLocation?: InterviewLocation;
    interview!: Interview;
}

export class ActiveInterviewersDetails {
    countOfInterviewers!: number;
}

export class ActiveInterviewersResponse implements ResponseData {
    result!: ActiveInterviewersDetails;
}

export class InterviewerWithLocationResponse implements ResponseData {
    interviewersWithLocations: InterviewerWithLocation[] = [];
}

export class OnlineInterviewers {
    interviewers!: Interviewer[];
    onlineLocations!: Map<number, InterviewLocation>;
}

export class OnlineInterviewersResponse implements ResponseData {
    result!: OnlineInterviewers;
}

export class OnlineInterviewAvailabilityResponse implements ResponseData {
    result!: OnlineInterviewAvailability;
}

export interface OnlineInterviewAvailability {
    interviewers: OnlineInterviewer[];
    calendar: OnlineInterviewCalendar;
}

export interface OnlineInterviewer {
    id: number;
    name: string;
    profileUrl?: string;
    notes?: string;
    bio?: string;
    applicantCount: number;
    applicantLimit: number;
    isPromoted: boolean;
    ratio: number;
    availability: number;
}

export interface OnlineInterviewCalendar {
    days: OnlineInterviewDay[];
}

export interface OnlineInterviewDay {
    isoDate: string;
    slots: OnlineInterviewSlot[];
}

export interface OnlineInterviewSlot {
    startTime: string;
    availableInterviewers: number[];
}

export class OnlineInterviewRequestResponse implements ResponseData {
    result!: OnlineInterviewRequest;
}

export interface OnlineInterviewRequest {
    requestId: number;
}

export class CompleteInterviewRequest implements ResponseData {
    result!: CompleteOnlineInterview;
}

export interface CompleteOnlineInterview {
    success: boolean;
    message?: string;
}

export class CheckSlotAvailabilityResponse implements ResponseData {
    result!: ReservationResponse;
}

export interface Reservation {
    interviewerId: number;
    date: string;
    time: string;
}

export interface ReservationResponse {
    booked: boolean;
    reservations: Reservation[];
}