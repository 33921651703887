import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '@aifs-shared/user/user.service';
import { AuthenticationService } from '@aifs-shared/auth/authentication.service';
import { ApplicationService } from '@aifs-shared/application/application.service';

import { TrackerService } from '@aifs-shared/tracker/tracker.service';
import { TrackerTaskComponent } from '@aifs-shared/tracker/tracker-task.component';

import { DisplayCardItem } from '@aifs-shared/display-card/display-card-item';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModal } from '@aifs-shared/modals/confirmation.modal';

import { DeleteReferenceResponse, LoadRefereeDetailsResponse, ReferenceService } from './reference.service';
import { RefereeDetails, ReferenceType } from './referee-details';

@Component({
    templateUrl: './review-references.component.html',
    styleUrls: ['./less/review-references.component.scss']
})
export class ReviewReferencesComponent extends TrackerTaskComponent implements OnInit, OnDestroy {

    constructor(
        router: Router,
        tracker: TrackerService,
        route: ActivatedRoute,
        private userService: UserService,
        private authService: AuthenticationService,
        private application: ApplicationService,
        private referenceService: ReferenceService,
        private modalService: NgbModal
    ) {
        super(router, tracker);
    }
    
    override ngOnInit() {
        super.ngOnInit();
        this.loadReferences();

        const task = this.tracker.activeTask;
        if(!task) console.warn('No activeTask!');
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
    }

    /**
     * @description Once we have enough referees, we can move 
     * forwards with the tracker
     */
    override stepNext(): void {
        // console.warn(`stepNext`);
        this.getRefereesAndValidate(true);
    }

    /**
     * @description return to home page
     */
    override stepPrevious(): boolean {
        this.router.navigateByUrl(this.tracker.homeRoute);
        return true;
    }

    loadReferences() {
        this.title = "Referees";
        localStorage.removeItem("tracker:returnUrl");

        this.getRefereesAndValidate(false);
        this.setNextEnabled(true);
    }

    // CA-2511 - use same function for initialisation
    // and for stepping next, as previously we were not
    // calling validate() on a step next
    getRefereesAndValidate(steppingNext = false): void {
        // console.warn(`getRefereesAndValidate`);
        const applicantRole = this.userService.applicantRole();
        if (applicantRole) {
            // console.warn(`hasApplicantRole`);
            //const applicantId: number = applicantRole.applicantId!;
            const isReturner = applicantRole.isReturner!;
            const activeSeason = applicantRole.activeApplicationSeason!;
            this.isNextSeason = applicantRole.isNextSeason!;

            // temp
            this.canAddHistory = true;
            this.referenceService
                .getReferees()
                .subscribe({
                    next: (res: LoadRefereeDetailsResponse) => {
                        if (res) {
                            // Iterate through the list and create DisplayCardItems
                            this.refereeLimit = res.refereeLimit;
                            this.references = res.details;

                            if (res.refereeLimit < this.references!.length && this.references!.length < 3) {
                                this.addRefereeCountString = "three";
                            } else {
                                this.addRefereeCountString = "more";
                            }

                            this.displayHistory = [];
                            this.references!.forEach((r: RefereeDetails) => {
                                this.displayHistory!.push(this.displayItemFromHistoryItem(r));
                            });

                            this.validatePage();

                            // CA-2511: if we are stepping next, do we need to
                            // now finish this task, having checked validity
                            // (which did not happen previously)?
                            if (steppingNext) {
                                // console.warn(`Stepping 'next', active task is:`, this.tracker.activeTask);
                                const task = this.tracker.activeTask;                                
                                if (task) {
                                    // console.warn(`Checking valid: ${this.isValid}`);
                                    if (this.isValid) {
                                        this.tracker.finishTask();
                                    } else {
                                        console.warn(`NOT completing task!`);
                                        this.tracker.markTaskAsIncomplete(task)
                                            .subscribe(r => {
                                                // Should probably have a mark task as incomplete
                                                this.router.navigateByUrl(this.tracker.homeRoute);
                                            });
                                    }
                                } else {
                                    console.error(`Didn't get task!`);
                                }
                            }
                        }
                    },
                    error: (error: any) => {
                        console.error(`${error}`);
                    }
                });
        }
    }

    /**
     * @description is this page valid?
     */
    validatePage() {
        const refCount = this.references ? this.references.length : 0;

        // NOTE - user has their own reference limit
        // Addendum from Charlotte testing: the user MUST enter 2, but is
        // ALLOWED to enter 3 (or their application limit)
        this.isValid = refCount >= 2; // || (this.specialReturnerCase && this.hasReturnerDoneEnough);
        this.canAddHistory = !this.references || refCount < this.refereeLimit;
    }

    addReferee() {
        this.application.setValue("id", null);

        this.tracker.setReturnUrl( "/references/add-reference");

        this.tracker.setTaskStorage("reference", null);
        this.tracker.setTaskStorage("reference_add", "Y");
        //this.router.navigateByUrl("/references/add-reference");
        this.router.navigateByUrl("/references/add-referee");
    }

    selectItem(item: DisplayCardItem) {
        this.selectReferenceFromCardItem(item);
    }

    editItem(item: DisplayCardItem) {
        if (this.selectReferenceFromCardItem(item)) {
            // Now navigate
            this.tracker.setReturnUrl( "/references/add-reference");
            this.router.navigateByUrl("/references/add-reference");
        }
    }

    deleteItem(item: DisplayCardItem) {
        const modalRef = this.modalService.open(ConfirmationModal);
        modalRef.componentInstance.title = 'Remove Referee';
        modalRef.componentInstance.body = 'Are you sure you want to delete this referee\'s details?';
        modalRef.result.then(result => {
            if (result) {
                // console.info(`Delete reference ${item.id}`);
                let editItem: RefereeDetails | undefined = this.references?.find((h: RefereeDetails) => h.id == item.id);

                if (!editItem) {
                    console.error(`Expected to find item ${item.id} in ${this.references?.length} reference providers`);
                    return false;
                }
                this.referenceService.deleteReference(editItem.id)
                    .subscribe({
                        next: (r: DeleteReferenceResponse) => {
                            // console.info(`Successfully deleted reference ${editItem.id}`);
                            this.loadReferences();
                        },
                        error: (error: any) => {
                            this.pageError(error);
                        }
                    });
            }
            return;
        });
    }

    additionalButtonPressed(item: DisplayCardItem) {
        //console.info(`Additional button of id ${item.buttonSelected} was pressed`);
        switch (item.buttonSelected) {
            case 1:
                //console.info(`Go to paper reference criteria screen`);

                if (this.selectReferenceFromCardItem(item)) {

                    const reference = this.tracker.getTaskStorage("reference") as RefereeDetails;
                    if (reference.imageId || 0 != 0) {
                        this.viewUploadedReference(reference);
                    } else {
                        this.router.navigateByUrl("/references/upload");
                    }
                }
                break;
        }
    }

    viewUploadedReference(reference: RefereeDetails): void {
        sessionStorage.setItem("upload_next", "/references/review");
        sessionStorage.setItem("upload_prev", "/references/review");
        this.router.navigateByUrl(`uploads/view/${reference.imageId}`)
    }


    selectReferenceFromCardItem(item: DisplayCardItem): boolean {
        const editItem: RefereeDetails | undefined = this.references?.find((h: RefereeDetails) => h.id == item.id);

        if (!editItem) {
            console.error(`Expected to find item ${item.id} in ${this.references?.length} reference providers`);
            return false;
        }

        this.tracker.setTaskStorage("reference", editItem);
        return true;
    }

    displayItemFromHistoryItem(r: RefereeDetails): DisplayCardItem {
        const from = this.getMonthYearDisplayDate(r.validFrom);
        let to: string = '';
        if (r.validTo) {
            to = this.getMonthYearDisplayDate(r.validTo);
        }

        const details: string = r.currentlyPresent ? "Current" : (to ? `${from} to ${to}` : `${from} to present`);

        let statusClass: string;
        switch (r.referenceStatus) {
            case "Approved": {
                statusClass = 'success';
                break;
            }
            case "Incomplete":
            case "Rejected":
            case "Invalid Email Address":
                {
                    statusClass = 'failure';
                    break;
                }
            case "Reference Complete":
            case "Started by Referee":
            case "Awaiting Approval":
            case "Reference Requested":
            case "Details Entered":
                {
                    statusClass = 'warning';
                    break;
                }
            default: { //unexpected status!
                statusClass = 'failure';
            }
        }

        // CA1345 - try to hide images uploaded by office users
        const currentUserName = this.userService.currentUserName;
        const matches = !r.addedBy || currentUserName.toLowerCase() === r.addedBy?.toLowerCase();

        // console.log(`Does '${currentUserName}' match reference's uploader? '${r.addedBy}' === ${matches}`);

        let d = new DisplayCardItem({
            id: r.id,
            title: `${r.firstName} ${r.lastName}`,
            subtitle: `${r.referenceStatus}`,
            subtitleClass: statusClass,
            details: `${r.position}, ${r.companyName}`,
            detailsExtra: `Dates known: ${details}`,
            canDelete: r.canDelete && matches,          // CA1345 - only permissible if it's not an office user
            canEdit: r.canEdit && matches               // CA1345 - only permissible if it's not an office user
        });

        if (r.referenceType == ReferenceType.Paper && matches) {
            // console.info(`Paper Reference: ${r.imageId || -1}`);
            d.additionalButtonItems = [{ id: 1, label: r.imageId || 0 != 0 ? "View Reference" : "Upload Reference", cssClass: "additional-1" }];
            // } else {
            //     // console.log(`Not paper reference`);
        }

        return d;
    }

    private getMonthYearDisplayDate(dateString : string) : string {
        const date = new Date(dateString.substring(0,10));
        const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        const locale = "en-gb";
        return `${utcDate.toLocaleString(locale, { month: "long" })} ${utcDate.getFullYear()}`;
    }

    references?: RefereeDetails[];
    displayHistory: DisplayCardItem[] = [];

    refereeLimit: number = 2;
    addRefereeCountString?: string;

    canAddHistory = false;
    isValid = false;
    isNextSeason = false;

    // remove once task stuff in
    title: string = "Temporary Title";
}
