import { getEnvironment } from './ca-environment'

export const environment = getEnvironment({
    isProduction: false,
    isAzure: false,
    applicationServiceUrl: 'https://test-api.mycampamerica.com',
    dbsServiceUrl: 'https://test-api.mycampamerica.com',
    eventServiceUrl: 'https://test-api.mycampamerica.com',
    featureServiceUrl: 'https://test-api.mycampamerica.com',
    flightServiceUrl: 'https://test-api.mycampamerica.com',
    formServiceUrl: 'https://test-api.mycampamerica.com',
    insuranceServiceUrl: 'https://test-api.mycampamerica.com',
    interviewServiceUrl: 'https://test-api.mycampamerica.com',
    interviewerServiceUrl: 'https://test-api.mycampamerica.com',
    issueServiceUrl: 'https://test-api.mycampamerica.com',
    messageServiceUrl: 'https://test-api.mycampamerica.com',
    orientationServiceUrl: 'https://test-api.mycampamerica.com',
    paymentServiceUrl: 'https://test-api.mycampamerica.com',
    placementServiceUrl: 'https://test-api.mycampamerica.com',
    signalRServiceUrl: 'https://test-api.mycampamerica.com',
    referenceServiceUrl: 'https://test-api.mycampamerica.com',
    selfProposalServiceUrl: 'https://test-api.mycampamerica.com',
    skillServiceUrl: 'https://test-api.mycampamerica.com',
    trackerServiceUrl: 'https://test-api.mycampamerica.com',
    uploadServiceUrl: 'https://test-api.mycampamerica.com',
    userServiceUrl: 'https://test-api.mycampamerica.com',

    // applicationServiceUrl: 'http://localhost:30004/api',
    // dbsServiceUrl: 'http://localhost:30020/api',
    // eventServiceUrl: 'http://localhost:30006/api',
    // featureServiceUrl: 'http://localhost:30023/api',
    // flightServiceUrl: 'http://localhost:30007/api',
    // formServiceUrl: 'http://localhost:30022/api',
    // insuranceServiceUrl: 'http://localhost:30008/api',
    // interviewServiceUrl: 'http://localhost:30005/api',
    // interviewerServiceUrl: 'http://localhost:30005/api',
    // issueServiceUrl: 'http://localhost:30016/api',
    // messageServiceUrl: 'http://localhost:30009/api',
    // orientationServiceUrl: 'http://localhost:30025/api',
    // paymentServiceUrl: 'http://localhost:30010/api',
    // placementServiceUrl: 'http://localhost:30011/api',
    // referenceServiceUrl: 'http://localhost:30013/api',
    // selfProposalServiceUrl: 'http://localhost:30024/api',
    // skillServiceUrl: 'http://localhost:30015/api',
    // trackerServiceUrl: 'http://localhost:30012/api',
    // uploadServiceUrl: 'http://localhost:30014/api',
    // userServiceUrl: 'http://localhost:30000/api',
    cloudImage: {
        id: 'critkxioza',
        replace: { s3url: 'https://campamericadev.s3.amazonaws.com/resources/', alias: '_aifsukcadev_' },
    },

    // dotPay: {
    //   account: '792770',
    //   paymentsUrl: 'https://ssl.dotpay.pl/test_payment/',
    //   language: 'EN',
    // },
    secureTrading: {
        paymentsUrl: 'https://payments.securetrading.net/process/payments/choice',
        siteReference: 'test_aifsca61207',
        profile: "default",
        version: "2"
    },

    sessionTimeout: {
        idle: 600,
        timeout: 1,
    },

    googleMaps: {
        apiKey: "AIzaSyB6Q9YpMMshASCdzytnboAMqnLGoNL86vg"
    },
    signalRUrl: 'https://test-api.mycampamerica.com/push',
    appInsights: {
        instrumentationKey: '6d549cfa-8349-4cd7-8d30-51b000563b4e'
    },

    googleAnalytics: {
        measurementId: 'G-5C0NE8E6CK'
    },
    applicantView: {
        isPayNowEnabled: true,
        isDs2019LinkViewable: true,
        isMedicalInformationViewable: true,
        isHeaderVisible: false,
        isActivityCheckActive: false
    },
    hubSpotTrackingScript: '//js-eu1.hs-scripts.com/144055443.js'
})
