<table class="interview-details">
    <tr *ngIf="!isOnline">
        <td>Location</td>
        <td>{{location}}</td>
    </tr>
    <tr *ngIf="dateString">
        <td>Date</td>
        <td>{{dateString}}</td>
    </tr>
    <tr *ngIf="timeString">
        <td>Time</td>
        <td>{{timeString}}</td>
    </tr>
    <tr *ngIf="!isHomePage">
        <td>Notes</td>
        <td>{{notes}}</td>
    </tr>
    <tr *ngIf="isHomePage">
        <td>Interviewer</td>
        <td>{{interviewerName}}</td>
    </tr>
</table>
