import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from '../shared/common/base-service'
import { ResponseData } from '@aifs-shared/common/response-data';
import { BaseResponse, ResponseResultCode } from '@aifs-shared/common/base-response';

import { Observable, Subject } from 'rxjs';

import { catchError, map } from 'rxjs/operators'


import { AllEvents } from './all-events';
import { EventDetails } from './event-details';

@Injectable()
export class EventService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    public getAllEvents(applicationId: number, countryCode: string): Observable<AllEvents> {
        let subject = new Subject<AllEvents>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_AllEvents(applicationId, countryCode))
            .subscribe(
                data => {
                    let response = this.getResultData<AllEvents>(data);
                    subject.next(response);
                },
                error => {
                    this.handleError(error);
                })

        return subject;
    }

    public getEventDetails(eventId: number): Observable<EventDetails> {
        let subject = new Subject<EventDetails>();

        this.http
            .get<BaseResponse>(environment.ServiceUrl_EventDetails(eventId))
            .subscribe(
                data => {
                    let response = this.getResultData<EventDetails>(data);
                    subject.next(response);
                },
                error => {
                    this.handleError(error);
                })

        return subject;
    }

    public attendEvent(applicationId: number, eventId: number): Observable<EventDetails> {
        let subject = new Subject<EventDetails>();

        this.http
            .post<BaseResponse>(environment.ServiceUrl_AttendEvent(applicationId),
                {
                    "applicationId": applicationId,
                    "eventId": eventId
                })
            .subscribe(
                data => {
                    let response = this.getResultData<EventDetails>(data);
                    subject.next(response);
                },
                error => {
                    this.handleError(error);
                })

        return subject;
    }
}