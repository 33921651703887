import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInterviewDetails } from '../../interview/application-interview-details';

@Component({
    selector: 'app-interview-info',
    templateUrl: './interview-info.component.html',
    styleUrls: ['./interview-info.component.scss']
})
export class InterviewInfoComponent implements OnInit {

    @Input() interviewInfo!: ApplicationInterviewDetails;
    @Input() isHomePage!: boolean;

    ngOnInit() {

        let date = this.interviewInfo.interview.interviewDateTime;
        if (date) {
            const monthIdx = +date.substring(5, 7);
            const monthName = this.months[monthIdx - 1];
            const day = date.substring(8, 10);
            const hh = date.substring(11, 13);
            const mm = date.substring(14, 16);
            this.dateString = `${day} ${monthName}`;
            this.timeString = this.formatAMPM(+hh, +mm);
        }
        this.isOnline = this.interviewInfo.interview.isOnline;
        this.location = this.interviewInfo.interview.location;
        if (this.isHomePage) {
            this.interviewerName = this.interviewInfo.interviewer.firstName + " " + this.interviewInfo.interviewer.lastName;
        }
        else {
            this.notes = this.interviewInfo.interview.notes;
        }

        // console.log(`isOnline: ${this.isOnline}`);
    }

    formatAMPM(hours: number, minutes: number) {
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        let minutesStr = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutesStr + ' ' + ampm;
        return strTime;
    }

    interviewerName!: string;
    date?: Date;
    dateString?: string;
    timeString?: string;
    location?: string;
    notes?: string;
    isOnline?: boolean;

    months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    days = ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
}
